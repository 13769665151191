import {useState} from 'react';
import { AuthStatus } from '../Authentication/authStatus';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import IonscreenLogo from '../IonScreenLogo.svg' //https://www.iconfinder.com/search/icons?q=eye&price=free
import PersonIcon from '../AccountIcon_Small.png'

const HamburgerMenu = () => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    
    const context = useIonScreenAuth();

    const onAccountClicked = () => {

    }

    return(
        <div className="flex items-center justify-between border-b border-gray-400 py-8">
            <div className="text-3xl font-bold text-blue-600 font-mono flex">IonScreen<img src={IonscreenLogo} alt='' className='ml-7 invisible md:visible'></img></div> 
            
            {context.authStatus == AuthStatus.LoggedIn &&
            <>
            <nav>
                <section className="MOBILE-MENU flex lg:hidden">
                    <div
                        className="HAMBURGER-ICON space-y-2"
                        onClick={() => setIsNavOpen((prev) => !prev)}
                    >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-600"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}>
            <div
              className="absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)}
            >
              <svg
                className="h-8 w-8 text-gray-600"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/">Home</a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/playlistitems">PlayListItems</a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/playlists">PlayLists</a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/displays">Displays</a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/credentials">Credentials</a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a href="/credentialauth">Authentication</a>
              </li>
            </ul>
          </div>
        </section>

        <ul className="DESKTOP-MENU hidden space-x-8 lg:flex">
          <li>
            <a href="/">Home</a>
          </li>
          <li>
            <a href="/playlistitems">PlayListItems</a>
          </li>
          <li>
            <a href="/playlists">PlayLists</a>
          </li>
          <li>
            <a href="/displays">Displays</a>
          </li>
          <li>
            <a href="/credentials">Credentials</a>
          </li>
          <li>
            <a href="/credentialauth">Authentication</a>
          </li>
        </ul>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    <a href="/account">
      <div className="group flex relative">
        
        <img src={PersonIcon} alt='' className='rounded-full ml-7' onClick={onAccountClicked}></img> 
        <span className="group-hover:opacity-100 transition-opacity bg-gray-400 px-1 text-sm text-gray-100 rounded-md absolute left-1/2 translate-x-1/2 translate-y-full opacity-0 m-4 mx-auto">
          <div>{context.userName}</div>
          <div>Tenant: {context.currentTenantInfo.name} ({context.currentTenantInfo.id}) </div>
        </span>
      </div></a>
    </>}
        </div>
    );
}

export default HamburgerMenu;