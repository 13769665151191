import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import MovableItem from './MovableItem';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';



const Page_Registration = () => {
    
    const { instance } = useMsal();
    const [tenantName, setTenantName] = useState<string>("");
    const context = useIonScreenAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (instance.getActiveAccount()?.name !== null){
            //console.log(instance.getActiveAccount());
            setTenantName(instance.getActiveAccount()?.username as string);
        }
      }, []);

    
    const onTenantNameChanged = (event:FormEvent<HTMLInputElement>) => {
        setTenantName(event.currentTarget.value);
    }

    const onRegisterClicked = async () => {
        if (tenantName === "") {
            alert('enter tenant name code first');
            return;
        }

        let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"tenantName":tenantName, "timeZone":timezone}
            var result = await axios.get(config.backEndAppApi + `/api/CreateTenant`, axiosRequestConfig);

            // we should redirect to an authenticated page
            //let a:NavigateOptions = {}
            navigate('/');
            window.location.reload();
        }
        catch {

        }
        
        
    }
    
    return(
        <>
            <div>TenantName. Pick a name for your organisation.</div>
            <input className='rounded border' value={tenantName} onChange={onTenantNameChanged}/>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={onRegisterClicked}>Register</button>
            <br/>
            <div>By clicking "Register", you agree to our <a href="https://www.ionscreen.online/terms" target="_blank" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">Terms</a></div>

        </>
        );
}

export default Page_Registration;
