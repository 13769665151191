import { useState, useEffect, FormEvent, ChangeEvent, useRef} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { useNavigate, useParams } from 'react-router-dom';
import { PreDefinedUpdatingScheduleDto } from '../DTO/PreDefinedUpdatingScheduleDto';
import { CredentialDto } from '../DTO/CredentialDto';
import { PlayListItemtypeDto as PlayListItemTypeDto } from '../DTO/PlayListItemTypeDto';
import LoadingComponent from './LoadingComponent';



const Page_PlayListItem = () => {
    let { id } = useParams(); 
    const context = useIonScreenAuth();
    //const result = useGetDisplayItems(context);

    const preDefinedSchedules = useRef<PreDefinedUpdatingScheduleDto[]>([]);
    const credentials = useRef<CredentialDto[]>([]);
    const playListItemTypes = useRef<PlayListItemTypeDto[]>([]);
    const [playListItem, setPlayListItem] = useState<PlayListItemDto|null>(null);

    const navigate = useNavigate();
    
    useEffect(() => {
        const abortController = new AbortController();
        getPlayListItemAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, [id]);
      const [loading,setLoading] = useState(true)
      

    const getPlayListItemAsync = async (abortController:AbortController)  => {
        try {
            //TODO - this might be a race contidion in the future, but it worked like this before
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);

            let preDefineSchedulePromise = axios.get<PreDefinedUpdatingScheduleDto[]>(config.backEndAppApi + `/api/GetPreDefinedUpdatingSchedules`, axiosRequestConfig);
            let getCredentialsPromise = axios.get<CredentialDto[]>(config.backEndAppApi + `/api/GetCredentials`, axiosRequestConfig);
            let getPlayListItemTypesPromise = axios.get<PlayListItemTypeDto[]>(config.backEndAppApi + `/api/GetPlayListDefinitionItemTypes`, axiosRequestConfig);

            let predefinedScheduleResult = await preDefineSchedulePromise;
            let getCredentialResult = await getCredentialsPromise;
            let getPlayListItemTypesResult =  await getPlayListItemTypesPromise;

            preDefinedSchedules.current = predefinedScheduleResult.data;
            credentials.current = getCredentialResult.data;
            playListItemTypes.current = getPlayListItemTypesResult.data;

            if (id != undefined) {
                console.log('Fetching item');
                axiosRequestConfig.params = {"id":id}
                let result = await axios.get<PlayListItemDto>(config.backEndAppApi + `/api/GetPlayListItem`, axiosRequestConfig);
                setPlayListItem(result.data);
                return;
            }

            let item:PlayListItemDto = {
                playListItemType: playListItemTypes.current[0].type,
                settings: playListItemTypes.current[0].settings,
                credentialId: -1,
                id: -1,
                title: '',
                description: '<New item>',
                predefinedUpdatingScheduleId: -1, // default to manual trigger
                enabled: true
            };
            console.log(item);
            setPlayListItem(item);
            
        }
        catch(e) {
            console.log(e);
        }
        finally
        {
            setLoading(false);
        }
    }

    
    

    const HandleStringSettingChange = (event:ChangeEvent<HTMLInputElement>) => {
        let newItem = {...playListItem, settings:{...playListItem?.settings, [event.currentTarget.id]:event.currentTarget.value}}  as PlayListItemDto;
        console.log(newItem);

        setPlayListItem(newItem);
    }

    const HandleBooleanSettingChange = (event:ChangeEvent<HTMLInputElement>) => {
        let newItem = {...playListItem, settings:{...playListItem?.settings, [event.currentTarget.id]:event.currentTarget.checked}}  as PlayListItemDto;
        
        console.log(newItem);

        setPlayListItem(newItem);
    }


    const HandleSaveClicked = async () => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            if (id != undefined) {
                await axios.post<PlayListItemDto>(config.backEndAppApi + `/api/UpdatePlayListItem`, playListItem, axiosRequestConfig);
            }
            else{
                var result = await axios.post<PlayListItemDto>(config.backEndAppApi + `/api/AddPlayListItem`, playListItem, axiosRequestConfig);

                navigate(`/playlistitem/${result.data.id}`); 
                let newItem = {...playListItem, id: result.data.id};
                setPlayListItem(newItem as PlayListItemDto);
            }
        }
        catch(error) {
            console.log(error);
        }

    }

    const isBooleanProperty = (obj:object, property:string):boolean => {
        const keyAccessor = property as keyof typeof obj;
        return typeof obj[keyAccessor] === 'boolean';
    }


    //hmm smells like a generic
    const getStringSettingValueByMemberName = (obj:object, property:string):string => {

        const keyAccessor = property as keyof typeof obj;
         let retVal = obj[keyAccessor];

         if (retVal === null) {
            return '';
         }
         //console.log(retVal);
         return retVal;
    }

    const getBooleanSettingValueByMemberName = (obj:object, property:string):boolean => {

        const keyAccessor = property as keyof typeof obj;
        return obj[keyAccessor];
    }

    const onScheduleSelectionChanged = async (event: FormEvent<HTMLSelectElement>) => {

        let newItem = {...playListItem, predefinedUpdatingScheduleId: +event.currentTarget.value};
        setPlayListItem(newItem as PlayListItemDto);
    }
    const onCredentialSelectionChanged = async (event: FormEvent<HTMLSelectElement>) => {
        let newItem = {...playListItem, credentialId: +event.currentTarget.value};
        setPlayListItem(newItem as PlayListItemDto);
    }

    const onTitleChanged = (event:FormEvent<HTMLInputElement>) => {
        setPlayListItem({...playListItem, title:event.currentTarget.value} as PlayListItemDto)
    }
    const onDescriptionChanged = (event:FormEvent<HTMLInputElement>) => {
        setPlayListItem({...playListItem, description:event.currentTarget.value} as PlayListItemDto)
    }

    const onTypeSelectionChanged = async (event: FormEvent<HTMLSelectElement>) => {
        let newType = playListItemTypes.current.find(i=> i.type == event.currentTarget.value);
        if (newType === null) {
            console.log('type could not be found');
            return;
        }

        setPlayListItem({...playListItem, playListItemType: newType?.type, settings:newType?.settings} as PlayListItemDto);
    }

    
    const onEnabledChanged = (event:FormEvent<HTMLInputElement>) => {
        setPlayListItem({...playListItem, enabled:event.currentTarget.checked} as PlayListItemDto);
    }

    const HandleDeleteClicked = async () => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"id":id}

            await axios.get(config.backEndAppApi + `/api/DeletePlayListItem`, axiosRequestConfig);
            navigate(`/playlistitems`); 
        }
        catch(error) {
            console.log(error);
        }
    }

    const HandleCancelClicked = async () => {
        navigate(`/playlistitems`); 
    }

    if (loading) {
        return (<LoadingComponent/>);
    }
    if (playListItem === null) {
        return (<>Loading</>);
    }
    

    return(
        
        <>
             <h1 className='underline text-xl'>Playlist item - {`${playListItem?.description} (${playListItem?.id})`}</h1>        
             <br/><br/>
             <div>Description (just a name for you to remember it)</div>
             <input className='w-full rounded border' value={playListItem.description} onChange={onDescriptionChanged}/>
             <br/><br/>
             <div>Title (Shown on the slide)</div>
             <input className='w-full rounded border' value={playListItem.title} onChange={onTitleChanged}/>
             <br/><br/>
             <input type="checkbox" defaultChecked={playListItem.enabled} onChange={onEnabledChanged}/>Enabled
             <br/><br/>

             <div>Schedule</div>
             <select className='rounded border' onChange={onScheduleSelectionChanged}  value={playListItem.predefinedUpdatingScheduleId}>
                {preDefinedSchedules.current.map( s=>(<option key={`${s.id}`} value={s.id}>{`${s.description} (${s.id})`} </option>))}
            </select>
            <br/><br/>
             <div>Credential</div>
             <select className='rounded border' onChange={onCredentialSelectionChanged}  value={playListItem.credentialId}>
                <option key={`-1`} value={-1}>{`Manual`} </option>
                {credentials.current.map( c=>(<option key={`${c.id}`} value={c.id}>{`${c.description} (${c.id}) - ${c.userName}`} </option>))}
            </select>

            <br/>
            <br/>
            <div>Type</div>
             <select className='rounded border' onChange={onTypeSelectionChanged}  value={playListItem.playListItemType}>
                {playListItemTypes.current.map( s=>(<option key={s.type} value={s.type}>{s.type} </option>))}
            </select>

            <br/>
            <br/>
             
             {Object.getOwnPropertyNames(playListItem.settings).map(p => 
                (<div key={p}>
                    <div className='font-bold' >{p}</div>
                    {isBooleanProperty(playListItem.settings, p)?
                        <input type="checkbox" id={p} checked={getBooleanSettingValueByMemberName(playListItem.settings, p)} onChange={HandleBooleanSettingChange} value={undefined}/>
                        :
                        (<input id={p} className='w-full rounded border'  onChange={HandleStringSettingChange} value={getStringSettingValueByMemberName(playListItem.settings, p)}></input>)
                    }
                </div>))}
             <br/>
             <br/>
             <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleSaveClicked}>{id != undefined?'Save':'Add'}</button>
             {id != undefined?<button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleDeleteClicked}>{'Delete'}</button>:<></>}
             <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleCancelClicked}>Cancel</button>
        </>
        );
}

export default Page_PlayListItem;



