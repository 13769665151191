import {useEffect, useRef} from 'react';
import './App.css';
import { msalConfig } from "./authConfig";
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

import { AuthenticationProvider } from './Authentication/Components/AuthenticationProvider';

import { PageWithAuthError } from './PageWithAuthError';
import { TemplateUnAuthenticated } from './Authentication/Components/TemplateUnAuthenticated';
import { TemplateAuthenticatedButNoTenant } from './Authentication/Components/TemplateAuthenticatedButNoTenant';
import { TemplateAuthenticated } from './Authentication/Components/TemplateAuthenticated';
import { TemplateAuthentication } from './Authentication/Components/TemplateAuthentication';
import { TemplateAuthenticationError } from './Authentication/Components/TemplateAuthenticationError';
import Page_wrapper_Master from './Components/Page_Wrapper';
import Page_Login from './Components/Page_Login';
import Page_Registration from './Components/Page_Registration';
import LoadingComponent from './Components/LoadingComponent';
import axios, {  } from 'axios';
import config from './Config';
import { BrowserRouter, Route, Router, Routes } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import HamburgerMenu from './Components/HamburgerMenu';

//const pca = new PublicClientApplication(msalConfig);

type AppProps = {
  pca: IPublicClientApplication;
};

function App({ pca }: AppProps) {

  useEffect(() => {
    wakeupAsync();
  }, []); // only at startup

  const wakeupAsync = async ()  => {
    try {
        await axios.get(config.backEndWakeupApi + `/api/wakeup`);
    }
    catch(e) {
        console.log(e);
    }
}



//<MsalProvider instance={pca}>
  return (
    <div className="container mx-auto mb-8 px-8 max-w-screen-xl place-content-center">
       <MsalProvider instance={pca}>
          <AuthenticationProvider>
            <HamburgerMenu/>
            <TemplateUnAuthenticated>
              <Page_Login/>
            </TemplateUnAuthenticated>
            <TemplateAuthenticatedButNoTenant>
              <BrowserRouter>
                <Routes>
                  <Route path="/*" element={<Page_Registration/>}/>
                </Routes>
              </BrowserRouter>
            </TemplateAuthenticatedButNoTenant>
            <TemplateAuthenticated>
              <Page_wrapper_Master/>
            </TemplateAuthenticated>
            <TemplateAuthentication>
              <LoadingComponent/>
              </TemplateAuthentication>
            <TemplateAuthenticationError>
              <PageWithAuthError></PageWithAuthError>
            </TemplateAuthenticationError>
          </AuthenticationProvider>
          </MsalProvider>
    </div>
  );
}

export default App;
