import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import MovableItem from './MovableItem';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';



const Page_Login = () => {
    
    const { instance } = useMsal();
    
    const onLoginClicked = () => {
        instance.loginRedirect(loginRequest).catch(e => {
          console.error(e);
      });
    }
    
    return(
        <div className='grid place-items-center'>
            <br/>
            <br/>
            <div>Please log in or create an account.</div>
            <br/>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded place-item-center" onClick={onLoginClicked}>Login/create account</button>
        </div>
        );
}

export default Page_Login;
