import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import { PlayListItemDto } from '../DTO/PlayListItemDto';

type movableItemProps = {
    selected:boolean
    playListItem:PlayListItemDto
    showFromHour:number
    showFromMinute:number
    showUntilHour:number
    showUntilMinute:number
    setNewTime(id:number, arg0: number, arg1: number, arg2: number, arg3: number): unknown;
}

const formatTimePart = (value:number):string => {
 return ("0" + value).slice (-2);
}

//<input className='w-full rounded border' value={props.showFromMinute} />

const MovableItem = (props:movableItemProps) => {



    const onFromHourChanged = (event:FormEvent<HTMLInputElement>) => {
        let newValue = +event.currentTarget.value;
        props.setNewTime(props.playListItem.id, newValue,props.showFromMinute,props.showUntilHour, props.showUntilMinute);
    }

    const onFromMinuteChanged = (event:FormEvent<HTMLInputElement>) => {
        let newValue = +event.currentTarget.value;
        props.setNewTime(props.playListItem.id, props.showFromHour,newValue,props.showUntilHour, props.showUntilMinute);
    }

    const onToHourChanged = (event:FormEvent<HTMLInputElement>) => {
        let newValue = +event.currentTarget.value;
        props.setNewTime(props.playListItem.id, props.showFromHour,props.showFromMinute,newValue, props.showUntilMinute);
    }

    const onToMinuteChanged = (event:FormEvent<HTMLInputElement>) => {
        let newValue = +event.currentTarget.value;
        props.setNewTime(props.playListItem.id, props.showFromHour,props.showFromMinute,props.showUntilHour, newValue);
    }

    
let classClock = "w-6 h-6 rounded border align-bottom";
if (props.selected) {
    classClock += " bg-sky-500/50";
}
//{formatTimePart(props.showFromHour)}:{formatTimePart(props.showFromMinute)} to {formatTimePart(props.showUntilHour)}:{formatTimePart(props.showUntilMinute)}


    return(
        <div className= {props.selected?"bg-sky-500/50 rounded border flex ": "rounded border flex "}>
            
                <div className="w-96">
                    {props.playListItem.description} (id:{`${props.playListItem.id}`})   
                </div>
                <input className={classClock} value={formatTimePart(props.showFromHour)} onChange={onFromHourChanged}/>
                :
                <input className={classClock} value={formatTimePart(props.showFromMinute)}  onChange={onFromMinuteChanged} />
                -
                <input className={classClock} value={formatTimePart(props.showUntilHour)} onChange={onToHourChanged}/>
                :
                <input className={classClock} value={formatTimePart(props.showUntilMinute)} onChange={onToMinuteChanged}/>
                
            
        </div>
        );
}

export default MovableItem;



