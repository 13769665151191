//https://learn.microsoft.com/en-us/azure/active-directory-b2c/configure-authentication-sample-react-spa-app
//https://github.com/Azure-Samples/ms-identity-javascript-react-tutorial/blob/main/3-Authorization-II/2-call-api-b2c/SPA/src/authConfig.js

export const msalConfig = {
    auth: {
      clientId: "d3f1234d-a12a-4f87-b713-9c47d257e3a4",
      //authority: "https://login.microsoftonline.com/ionscreen.onmicrosoft.com", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      authority: "https://ionscreen.b2clogin.com/ionscreen.onmicrosoft.com/b2c_1_SignupOrSignIn",
      knownAuthorities: ["ionscreen.b2clogin.com"]
      //redirectUri: "http://localhost:3001", - this is supposed to be optional ----> we could use "/3"
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
  };
  
  // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
  export const loginRequest = {
   scopes: ["https://ionscreen.onmicrosoft.com/MainScope/Hello"]
  };
  
  // Add the endpoints here for Microsoft Graph API services you'd like to use.
  /*export const graphConfig = {
      graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me"
  };*/