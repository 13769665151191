import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import MovableItem from './MovableItem';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { useParams } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';



const Page_AuthenticationDiagnostics = () => {
    let { id } = useParams(); 
    const context = useIonScreenAuth();
    /*const result = useGetDisplayItems(context);

    const [playList, setPlayList] = useState<PlayListDto|null>(null);
    const [selectedId, setSelectedId] = useState<number>(-1);
    const [itemIdToAdd, setItemIdToAdd] = useState<number>(-1);
   
    useEffect(() => {
        if (result.data.length == 0) {
            return;
        }
        setItemIdToAdd(result.data[0].id);
        
      }, [result.data]);

      useEffect(() => {
        
        const abortController = new AbortController();
        getPlayListAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getPlayListAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);
            axiosRequestConfig.params = {"id":id}

            var result = await axios.get<PlayListDto>(config.backEndApi + `/api/GetPlayList`, axiosRequestConfig);
            setPlayList(result.data);
        }
        catch {

        }
    }

    const onActivePlayListItemSelected = (event: React.MouseEvent<HTMLDivElement>)=> {
        let selectedItem:number = +event.currentTarget.id;
        setSelectedId(selectedItem);
        console.log(selectedItem);
    }

    function getIndexOfSelectedItem(allowSingleItem:boolean=false):number {
        if (selectedId === -1) {
            return -1;
        }

        if (!allowSingleItem && 
            playList?.playListItemInfos.length === 1) {
            return -1;
        }
        if (playList?.playListItemInfos == null) {
            return -1;
        }
        
        return playList.playListItemInfos.findIndex(value => value.playListItemId === selectedId);
    } 

    const onUpClicked = (event: React.MouseEvent<HTMLButtonElement>)=> {
        let index = getIndexOfSelectedItem();
        if (index < 0)         {
            return;
        }
        if (index === 0) {
            return; // can't move it up
        }

        if (playList == null) {
            return;
        }

        var value = {... playList.playListItemInfos[index]};
        var prevValue = {...playList.playListItemInfos[index-1]};

        var newPlayList = {...playList} as PlayListDto;

        newPlayList.playListItemInfos[index] = prevValue;
        newPlayList.playListItemInfos[index-1] = value;

        setPlayList(newPlayList);
    }

    const onSaveClicked = async (event: React.MouseEvent<HTMLButtonElement>)=> {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            await axios.post<PlayListDto>(config.backEndApi + `/api/AddOrUpdatePlayList`, playList, axiosRequestConfig);
        }
        catch(error) {
            console.log(error);
        }
    }
    
    

    const onDownClicked = (event: React.MouseEvent<HTMLButtonElement>)=> {
        let index = getIndexOfSelectedItem();
        if (index < 0)         {
            return;
        }

        if (playList == null) {
            return;
        }

        if (index === playList.playListItemInfos.length -1) {
            //console.log('return it' + index + ' ' + playListItemIds.length);
            return; 
        }

        var value = {...playList.playListItemInfos[index]};
        var nextValue = {...playList.playListItemInfos[index+1]};

        var newPlayList = {...playList};
        newPlayList.playListItemInfos[index] = nextValue;
        newPlayList.playListItemInfos[index+1] = value;

        setPlayList(newPlayList);
    }

    const onSelectionToAddChanged = (event: FormEvent<HTMLSelectElement>) => {
        console.log(event.currentTarget.value);
        setItemIdToAdd(+event.currentTarget.value);
    }

    const onAddClicked = () => {
        
        if (itemIdToAdd === -1) {
            return;
        }
        if (playList == null) {
            return;
        }
        if (playList.playListItemInfos.find(i => i.playListItemId == itemIdToAdd) != undefined) {
            alert("Item is already in the list");
            return;
        }

        var newPlayList = {...playList};
        newPlayList.playListItemInfos.push({playListItemId:itemIdToAdd, showFromHour:0, showFromMinute:0, showUntilHour:23, showUntilMinute:59});

        setPlayList(newPlayList);
    }

    const onRemoveClicked = () => {
        
        if (selectedId < 0){
            return;
        }
        if (playList == null) {
            return;
        }
        
        var newPlayList = {...playList};
        
        newPlayList.playListItemInfos = newPlayList.playListItemInfos.filter(value => value.playListItemId !== selectedId);
        setPlayList(newPlayList);
    }


    if (result.loading)
    {
        return (<LoadingComponent/>)
    }

    if(playList == null) {
        return (<div>PlayList loading </div>)
    }

    return(
        <div>
             <h1 className='underline text-xl'>{`${playList.description} (${playList.id})`}</h1>        
             <div><button className="bg-blue-300 text-white font-bold py-2 px-4 rounded disabled:opacity-25" disabled={selectedId===-1} onClick={onUpClicked}>Up</button></div>
             <br/>
             
             <div><button className="bg-blue-300 text-white font-bold py-2 px-4 rounded disabled:opacity-25" disabled={selectedId===-1} onClick={onDownClicked}>Down</button></div>
             <br/>
             <div>
                {playList.playListItemInfos.map((item, index) => (<div key={`${item.playListItemId}`} id={`${item.playListItemId}`} onClick={onActivePlayListItemSelected}>
                        <MovableItem playListItem={result.data.find(i => i.id === item.playListItemId) as PlayListItemDto} 
                                     selected={selectedId === item.playListItemId}
                                     showFromHour={item.showFromHour}
                                     showFromMinute={item.showFromMinute}
                                     showUntilHour={item.showUntilHour}
                                     showUntilMinute={item.showUntilMinute}
                                     
                                     />
                    </div>))}
             </div>
             <br/>
             <select className='rounded border' onChange={onSelectionToAddChanged} >Select new item
                {result.data.map( i=>(<option key={`${i.id}`} value={`${i.id}`}>{i.description} ({i.title}) </option>))}
             </select>
             <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded disabled:opacity-25" disabled={itemIdToAdd===-1} onClick={onAddClicked}>Add</button>
             <br/>
             <br/>

             <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded disabled:opacity-25" disabled={selectedId===-1} onClick={onRemoveClicked}>Remove</button>
             <br/>
             <br/>


             <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded disabled:opacity-25" onClick={onSaveClicked} >Save</button>
        </div>
        )*/
        return (
            <div>TODO - {id}</div>
        );
}

export default Page_AuthenticationDiagnostics;



