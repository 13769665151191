import { useState, useEffect} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { CredentialDto } from '../DTO/CredentialDto';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';

const Page_Credentials = () => {
    const [credentials, setcredentials] = useState<CredentialDto[]>([]);
    const context = useIonScreenAuth();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true)
   
    useEffect(() => {
        const abortController = new AbortController();
        getCredentialsAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getCredentialsAsync = async (abortController:AbortController)  => {
        const source = axios.CancelToken.source();
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);
            var result = await axios.get<CredentialDto[]>(config.backEndAppApi + `/api/GetCredentials`, axiosRequestConfig);
            setcredentials(result.data);
        }
        catch {

        }
        finally {
            setLoading(false);
        }
    }  

    const HandleAddClicked = () => {
        navigate(`/credential`); 
    }


    if (loading) {
        return (<LoadingComponent/>);
    }
    
    return(
        <>
            <h1 className='underline text-xl'>Credentials</h1>
            <table className='table-auto'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Description</th>
                        <th>UserName</th>
                        <th>Mfa</th>
                    </tr>
                </thead>
                <tbody>
                {credentials.map(d => (<tr key={`${d.id}`}>
                    <td><Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`/credential/${d.id}`}>{d.id}</Link></td>
                    <td>{d.description}</td>
                    <td>{d.userName}</td>
                    <td>{d.mfaEnabled?'Yes':'No'}</td>
                </tr>))} 

             </tbody>
            </table>
            <br/>
            <br/>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleAddClicked}>Add</button>
        </>
        );
}

export default Page_Credentials;

