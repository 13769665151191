import React from 'react';
import { useIonScreenAuth } from "./Authentication/Hooks/useIonScreenAuth"

export const PageWithAuthError = () =>{

    const context = useIonScreenAuth();

    return( 
        <div>
            <div> Error</div>
            <div> {context.error}</div>
        </div>
    );
}