import { AxiosRequestConfig } from "axios";
import * as React from "react";
import { ITenantDto } from "../DTO/HelloDto";
import { AuthStatus } from "./authStatus";


export interface IIonScreenAuthContext {
    authStatus:AuthStatus;
    error:string;
    userName:string;
    currentTenantInfo:ITenantDto;
    //setUserData: (currentTenandId:number)=>void
    getAxiosRequestObjectAsync: (abortController:AbortController|null)=>(Promise<AxiosRequestConfig>)
}

export const defaultIonScreenAuthContext: IIonScreenAuthContext = {
    authStatus:AuthStatus.NotLoggedIn,
    error:"",
    userName:"",
    //tenants:[],
    currentTenantInfo:{id:-1, name:""},
    //setUserData:(currentTenandId:number)=>null 
    getAxiosRequestObjectAsync: (abortController:AbortController|null)=>{return new Promise<AxiosRequestConfig>((resolve, reject)=>{} )} // just sth temporary to make the compiler happy
};


//console.log("creating context");
//This context is use both by provider and consumer (consumer via useContext)
export const IonScreenAuthContext = React.createContext<IIonScreenAuthContext>(
    defaultIonScreenAuthContext
);
/*export const IonScreenConsumer = IonScreenAuthContext.Consumer;
*/