import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { IIonScreenAuthContext } from "../Authentication/IonScreenAuthContext";
import config from "../Config";
import { CredentialDto } from "../DTO/CredentialDto";
import { PlayListItemDto } from "../DTO/PlayListItemDto";
import { PreDefinedUpdatingScheduleDto } from "../DTO/PreDefinedUpdatingScheduleDto";

type getPlayListItemsResult={
    data: PlayListItemDto[]
    error:boolean,
    //errorMessage: string,
    loading:boolean
}


export const useGetDisplayItems = (context:IIonScreenAuthContext):getPlayListItemsResult => {
    
    const [data,setData] = useState<PlayListItemDto[]>([])
    const [error,setError] = useState(false)
    const [loading,setLoading] = useState(true)

    useEffect(() => {
        const abortController = new AbortController();
        getPlayListItemDefinitionAsync(abortController);
        return () => {
            abortController.abort();
          };
    }, []);

    const getPlayListItemDefinitionAsync = async (abortController:AbortController)  => {
        try {
            setLoading(true);
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null); //no fear of race conditions..
            var preDefineSchedulePromise = axios.get<PreDefinedUpdatingScheduleDto[]>(config.backEndAppApi + `/api/GetPreDefinedUpdatingSchedules`, axiosRequestConfig);
            var getCredentialsPromise = axios.get<CredentialDto[]>(config.backEndAppApi + `/api/GetCredentials`, axiosRequestConfig);

            var result = await axios.get<PlayListItemDto[]>(config.backEndAppApi + `/api/GetPlayListItems`, axiosRequestConfig);
            var predefinedScheduleResult = await preDefineSchedulePromise;
            var getCredentialResult = await getCredentialsPromise;
            //preDefinedSchedules.current = predefinedScheduleResult.data;
            //credentials.current = getCredentialResult.data;
            //setPlayListItemDefinitions(result.data);
            setData(result.data);

            
        }
        catch {

        }
        finally{
            setLoading(false);
        }
    }

    return {data:data, error:error, loading:loading};

}