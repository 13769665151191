import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import MovableItem from './MovableItem';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';



const Page_Account = () => {
    
    
    const navigate = useNavigate();
    const context = useIonScreenAuth();
    
    return(
        <>
            <div>UserName: {context.userName}</div>
            <br/>
            <div>Tenant: {context.currentTenantInfo.name} ({context.currentTenantInfo.id})</div>
            <br/>
            <div>Plan: Early tester</div>
            <br/>
            <div>Paymentstatus: na</div>
            <br/>
            <div>Paymenthistory: na</div>
            <br/>
            <br/>
            <br/>
            <br/>
            <div className='text-xs italic'>Version: {config.buildNumber}</div>
        </>
        );
}

export default Page_Account;
