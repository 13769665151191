import { useState, useEffect, ChangeEvent} from 'react';
import {Route, BrowserRouter, Routes, Outlet} from 'react-router-dom'
import HamburgerMenu from './HamburgerMenu';
import Page_Account from './Page_Account';
import Page_Authentication from './Page_Authentication';
import Page_Credential from './Page_Credential';
import Page_Credentials from './Page_Credentials';
import Page_Displays from './Page_Displays';
import Page_Home from './Page_Home';
import Page_PlayList from './Page_PlayList';
import Page_PlayListItem from './Page_PlayListItem';
import Page_PlayListItems from './Page_PlayListItems';
import Page_PlayLists from './Page_PlayLists';
import Page_AuthenticationDiagnostics from './Page_AuthenticationDiagnostics';

const Page_wrapper_Master = () => {
    

    return(
        <div>
            <div className="">
            
                <div className="mt-10">
                    <BrowserRouter>
                        <Routes>
                            <Route path="/playlistitems" element={<Page_PlayListItems/>}/>
                            <Route path="/playlistitem/:id" element={<Page_PlayListItem/>}/>
                            <Route path="/playlistitem" element={<Page_PlayListItem/>}/> 
                            <Route path="/playlists" element={<Page_PlayLists/>}/>
                            <Route path="/playlist/:id" element={<Page_PlayList/>}/>
                            <Route path="/displays" element={<Page_Displays/>}/>
                            <Route path="/credentials" element={<Page_Credentials/>}/>
                            <Route path="/credential" element={<Page_Credential/>}/>
                            <Route path="/credential/:id" element={<Page_Credential/>}/>
                            <Route path="/credentialauth" element={<Page_Authentication/>}/>
                            <Route path="/credentialauth/:id" element={<Page_AuthenticationDiagnostics/>}/>
                            <Route path="/account" element={<Page_Account/>}/>
                            <Route path="/" element={<Page_Home/>}></Route>
                            <Route path="/*" element={<>404 Not found</>}>
                                
                            </Route>
                        </Routes>
                        
                    </BrowserRouter>
                </div>
            </div>
        </div>
        );
}

export default Page_wrapper_Master;

