import { useState, useEffect, MouseEventHandler, useRef, ChangeEvent} from 'react';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import config from '../Config';
import { CredentialAndDomain } from '../DTO/CredentialAndDomain';
import { Console } from 'console';
import { AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser'; // todo make central
import { useMsal } from "@azure/msal-react";
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import LoadingComponent from './LoadingComponent';
import { AuthenticationDto } from '../DTO/AuthenticationDto';

const Page_Authentication = () => {
    const [credentials, setcredentials] = useState<CredentialAndDomain[]>([]);
    const context = useIonScreenAuth();
    const [loading,setLoading] = useState(true)
    const oneTimeCodes = useRef<string[]>([]);
   
    useEffect(() => {
        const abortController = new AbortController();
        getCredentialsAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getCredentialsAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);
            var result = await axios.get<CredentialAndDomain[]>(config.backEndAppApi + `/api/GetCredentialsAndDomains`, axiosRequestConfig);
            setcredentials(result.data);
            oneTimeCodes.current = new  Array<string>(result.data.length);
        }
        catch {

        }
        finally{
            setLoading(false);
        }
    }
    const HandleOtcChange = (event:ChangeEvent<HTMLInputElement>) => {
        var index = +event.currentTarget.id; // + converts to a number
        var newArray = {...oneTimeCodes.current};
        newArray[index]=event.currentTarget.value;
        oneTimeCodes.current = newArray;
    }
  

    const HandleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        var index = +event.currentTarget.id;
        var splitted = event.currentTarget.value.split(",", 2); 
        let id:string = splitted[0];
        let domain:string = splitted[1];

        console.log(id);
        console.log(domain);
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            //axiosRequestConfig.params = {"credentialId":id, "domain":domain}

            let authenticationDto:AuthenticationDto = {oneTimeCode: oneTimeCodes.current[index], credentialId:+id, domain:domain};
            var responseDto= await axios.post<AuthenticationDto>(config.backEndAppApi + `/api/Authenticate`, authenticationDto, axiosRequestConfig);

            oneTimeCodes.current = new  Array<string>(oneTimeCodes.current.length);

        }
        catch(error) {
            console.log(error);
        }
    }

    if (loading) {
        return (<LoadingComponent/>);
    }

    return(
        <div>
            <h1 className='underline text-xl'>MFA Authentication</h1>
            <table className='table-auto'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Description</th>
                        <th>UserName</th>
                        <th>Domain</th>
                        <th>OneTimeCode</th>
                        <th>Authenticate</th>
                    </tr>
                </thead>
                <tbody>
             {credentials.map((d, index) => (
             <tr key={`${d.credentialId}${d.domain}`}>
                <td>{d.credentialId}</td>
                <td>{d.description}</td>
                <td>{d.userName}</td>
                <td>{d.domain}</td>
                <td><input  className='rounded border' id={`${index}`} onChange={HandleOtcChange}/></td>
                <td><button id={`${index}`} value={[`${d.credentialId}`, d.domain ] } onClick={HandleClick}>Authenticate</button></td>
            </tr>))} 

             </tbody>
            </table>
        </div>
        );
}

export default Page_Authentication;

