import { useState, useEffect, FormEvent, ChangeEvent, useRef} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { useNavigate, useParams } from 'react-router-dom';
import { CredentialDto } from '../DTO/CredentialDto';
import LoadingComponent from './LoadingComponent';



const Page_Credential = () => {
    let { id } = useParams(); 
    const context = useIonScreenAuth();
    const [credential, setCredential] = useState<CredentialDto|null>(null);
    const pwd = useRef<string>('');
    const navigate = useNavigate();
     useEffect(() => {
        ;
        const abortController = new AbortController();
        getCredentialAsync(abortController)
        return () => {
            abortController.abort();
          };
      }, [id]);
      const [loading,setLoading] = useState(true)
      

    const getCredentialAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);

            if (id != undefined) {
                let result = await axios.get<CredentialDto>(config.backEndAppApi + `/api/Credential/${id}`, axiosRequestConfig);
                setCredential(result.data);
                return;
            }

            let item:CredentialDto = {
                id: -1,
                description: '<New item>',
                userName:'user@domain',
                mfaEnabled:false
            };
            console.log(item);
            setCredential(item);
            
        }
        catch(e) {
            console.log(e);
        }
        finally
        {
            setLoading(false);
        }
    }

    
    

    const HandleSaveClicked = async () => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);

            let objToSend = {...credential, password:pwd.current};
            //maybe we need to send the password

            if (id != undefined) {
                await axios.post(config.backEndAppApi + `/api/UpdateCredential`, objToSend, axiosRequestConfig);
                pwd.current = '';
            }
            else{
                var result = await axios.post<CredentialDto>(config.backEndAppApi + `/api/AddCredential`, objToSend, axiosRequestConfig);
                navigate(`/credential/${result.data.id}`); 
            }
        }
        catch(error) {
            console.log(error);
        }

    }

    const onUserNameChanged = (event:FormEvent<HTMLInputElement>) => {
        setCredential({...credential, userName:event.currentTarget.value} as CredentialDto)
    }
    
    const onDescriptionChanged = (event:FormEvent<HTMLInputElement>) => {
        setCredential({...credential, description:event.currentTarget.value} as CredentialDto)
    }
    const onMfaEnabledChanged = (event:FormEvent<HTMLInputElement>) => {
        setCredential({...credential, mfaEnabled:event.currentTarget.checked} as CredentialDto);
    }

    const onPwdChanged = (event:FormEvent<HTMLInputElement>) => {
        pwd.current = event.currentTarget.value;
    }

    if (loading) {
        return (<LoadingComponent/>);
    }

    return(
        <>
             <h1 className='underline text-xl'>Credential - {`${credential?.description} (${credential?.id})`}</h1>        
             <br/><br/>
             <div>Description (just a name for you to remember it)</div>
             <input className='w-full rounded border' value={credential?.description} onChange={onDescriptionChanged}/>
             <br/><br/>
             <div>UserName</div>
             <input  className='w-full rounded border' value={credential?.userName} onChange={onUserNameChanged}/>
             <br/><br/>
             
             <div>Password</div>
             <input  className='w-full rounded border' type="password" onChange={onPwdChanged}/>
             <br/><br/>
             <input type="checkbox" defaultChecked={credential?.mfaEnabled} onChange={onMfaEnabledChanged}/>Mfa Enabled
             <br/><br/>

             <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleSaveClicked}>{id != undefined?'Save':'Add'}</button>
        </>
        );
}

export default Page_Credential;



