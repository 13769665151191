import { useState, useEffect, MouseEventHandler, useRef} from 'react';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import config from '../Config';
import { CredentialAndDomain } from '../DTO/CredentialAndDomain';
import { Console } from 'console';
import { AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser'; // todo make central
import { useMsal } from "@azure/msal-react";
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { PreDefinedUpdatingScheduleDto } from '../DTO/PreDefinedUpdatingScheduleDto';
import { CredentialDto } from '../DTO/CredentialDto';
import { Link, useNavigate } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';
import { AuthenticationDto } from '../DTO/AuthenticationDto';

const Page_PlayListItems = () => {
    const [playListItemDefinitions, setPlayListItemDefinitions] = useState<PlayListItemDto[]>([]);
    const preDefinedSchedules = useRef<PreDefinedUpdatingScheduleDto[]>([]);
    const credentials = useRef<CredentialDto[]>([]);

    const context = useIonScreenAuth();
    const navigate = useNavigate();
    const [loading,setLoading] = useState(true)
   
    useEffect(() => {
        const abortController = new AbortController();
        getPlayListItemDefinitionAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getPlayListItemDefinitionAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null); // no fear of the race condition
            var preDefineSchedulePromise = axios.get<PreDefinedUpdatingScheduleDto[]>(config.backEndAppApi + `/api/GetPreDefinedUpdatingSchedules`, axiosRequestConfig);
            var getCredentialsPromise = axios.get<CredentialDto[]>(config.backEndAppApi + `/api/GetCredentials`, axiosRequestConfig);

            var result = await axios.get<PlayListItemDto[]>(config.backEndAppApi + `/api/GetPlayListItems`, axiosRequestConfig);
            var predefinedScheduleResult = await preDefineSchedulePromise;
            var getCredentialResult = await getCredentialsPromise;
            preDefinedSchedules.current = predefinedScheduleResult.data;
            credentials.current = getCredentialResult.data;
            setPlayListItemDefinitions(result.data);

            
        }
        catch {

        }
        finally{
            setLoading(false);
        }
    }  

    const getCredentialName = (credentialId:number):string => {
        if (credentialId === -1){
            return '-';
        }
        var credential = credentials.current.find(c=> c.id == credentialId);
        if (credential === undefined)
        {
            return `<unknown_${credentialId}>`;
        }
        return `${credential.description} (${credential.userName})`

    }
    const HandleAddClicked = () => {
        navigate(`/playlistitem`); 
    }
    const HandleForceUpdateClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        let index = +event.currentTarget.id;
        let id = event.currentTarget.value
        
        console.log(id);
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"id":id}

            var responseDto= await axios.get(config.backEndAppApi + `/api/ForceSlideCreation`, axiosRequestConfig);
           

        }
        catch(error) {
            console.log(error);
        }
    }


    if (loading) {
        return (<LoadingComponent/>);
    }
    
    return(
        <>
            <h1 className='underline text-xl'>PlayListItems</h1>
            <table className='table-auto'>
                <thead>
                    <tr>
                        <th className=''>Id</th>
                        <th>Enabled</th>
                        <th>Type</th>
                        <th>Description (Title)</th>
                        <th>Credential</th>
                        <th>Schedule</th>
                        <th>Update</th>
                    </tr>
                </thead>
                <tbody>
             {playListItemDefinitions.map(d => (<tr key={`${d.id}`}>
                <td><Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`/playlistitem/${d.id}`}>{d.id}</Link></td>
                <td>{d.enabled?'Yes':'No'}</td>
                <td>{d.playListItemType}</td>
                <td>{d.description}({d.title})</td>
                <td>{getCredentialName(d.credentialId)}</td>
                <td>{preDefinedSchedules.current.find(p => p.id === d.predefinedUpdatingScheduleId)?.description}</td>
                <td><button id={`${d.id}`} value={`${d.id}`} onClick={HandleForceUpdateClick} className="bg-blue-300 text-white py-2 px-4 rounded">U</button></td>
              </tr>))} 

             </tbody>
            </table>
            <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleAddClicked}>Add</button>
        </>
        );
}

export default Page_PlayListItems;

