import { useState, useEffect, ChangeEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import Page_PlayList from './Page_PlayList';
import { Link } from 'react-router-dom';
import LoadingComponent from './LoadingComponent';

const Page_PlayLists = () => {
    const [playLists, setPlayLists] = useState<PlayListDto[]>([]);
    const context = useIonScreenAuth();
    const [addDescription, setAddDescription] = useState<string>("");
    const [loading,setLoading] = useState(true)
   
    useEffect(() => {
        const abortController = new AbortController();
        getPlayListsAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getPlayListsAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(abortController);
            var result = await axios.get<PlayListDto[]>(config.backEndAppApi + `/api/GetPlayLists`, axiosRequestConfig);
            setPlayLists(result.data);
        }
        catch {

        }
        finally{
            setLoading(false);
        }
    }  

    const HandleAddDescriptionChange = (event:ChangeEvent<HTMLInputElement>) => {
        setAddDescription(event.currentTarget.value);
    }

    const HandleAddClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        
        if (addDescription === "")
        {
            alert('Enter description');
            return;
        }
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);

            let itemToAdd:PlayListDto = {id:-1, description:addDescription, playListItemInfos:[]};
            
            var newPlayList= await axios.post<PlayListDto>(config.backEndAppApi + `/api/AddOrUpdatePlayList`, itemToAdd, axiosRequestConfig);
            let copiedPlayLists = [...playLists, newPlayList.data];
            setPlayLists(copiedPlayLists);
            setAddDescription("");
        }
        catch(error) {
            console.log(error);
        }
    }
            
    if (loading) {
        return (<LoadingComponent/>);
    }

    return(
        <div>
            <h1 className='underline text-xl'>PlayLists</h1>
            <table className='table-auto'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Description</th>
                        <th># items</th>
                    </tr>
                </thead>
                <tbody>
                {playLists.map(p => (<tr key={`${p.id}`}><td><Link className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600" to={`/playlist/${p.id}`}>{p.id}</Link></td><td>{p.description}</td><td>{`${p.playListItemInfos.length}`}</td></tr>))} 

             </tbody>
            </table>
            <br/>
            <input  className='rounded border' onChange={HandleAddDescriptionChange} value={addDescription}/>
                <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleAddClick}>Add</button>
            
        </div>
        );
}

export default Page_PlayLists;

