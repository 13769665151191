declare global {
    interface Window { IonScreenConfig: any; }
}

window.IonScreenConfig = window.IonScreenConfig || {};

// the above comes from
// //https://stackoverflow.com/questions/12709074/how-do-you-explicitly-set-a-new-property-on-window-in-typescript

//https://www.youtube.com/watch?v=eGcosoC43vI

interface IConfig {
    backEndAppApi:string,
    backEndWakeupApi:string,
    buildNumber:string
}

const config:IConfig = window.IonScreenConfig;

export default config;