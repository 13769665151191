import { useState, useEffect, FormEvent} from 'react';
import axios, {  } from 'axios';
import config from '../Config';
import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { PlayListDto } from '../DTO/PlayListDto';
import { useGetDisplayItems } from '../Hooks/useGetPlayListItems';
import MovableItem from './MovableItem';
import { PlayListItemDto } from '../DTO/PlayListItemDto';
import { NavigateOptions, useNavigate, useParams } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';



const Page_Home = () => {
    
    const context = useIonScreenAuth();
    const navigate = useNavigate();

    

    
        
    
    
    return(
        <>
            Welcome {context.userName} to the IonScreen application.
            <br/>
            <br/>
            Get started by
            <ul className='list-disc ml-8'>
                  <li>Registering your credentials.</li>
                  <li>Creating new playlist items.</li>
                  <li>Combining these items to a playlist.</li>
                  <li>Registering a display.</li>
                  <li>And assigning a playlist to a display.</li>
                  <li>Then test using <a href="https://play.ionscreen.online" target="_blank" className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600">https://play.ionscreen.online</a> to view the results. You need to link the display device to the a display first using the registration code.</li>
                  <li>Everything looking good: get your streaming device!</li>
            </ul>
        </>
        );
}

export default Page_Home;
