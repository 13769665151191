import { useState, useEffect, MouseEventHandler, useRef, ChangeEventHandler, ChangeEvent, FormEvent} from 'react';
import axios, { AxiosHeaders, AxiosRequestConfig } from 'axios';
import config from '../Config';
import { CredentialAndDomain } from '../DTO/CredentialAndDomain';
import { Console } from 'console';
import { AuthenticationResult, InteractionRequiredAuthError, IPublicClientApplication } from '@azure/msal-browser'; // todo make central

import { useIonScreenAuth } from '../Authentication/Hooks/useIonScreenAuth';
import { Display } from '../DTO/Display';
import { stringify } from 'querystring';
import { PlayListDto } from '../DTO/PlayListDto';
import LoadingComponent from './LoadingComponent';
import moment from 'moment'

const Page_Displays = () => {
    const [displays, setDisplays] = useState<Display[]>([]);
    const registrationCodes = useRef<string[]>([]);
    const context = useIonScreenAuth();
    const [newDisplayName, setNewDisplayName] = useState<string>("");
    const playLists = useRef<PlayListDto[]>([]);
    const [loading,setLoading] = useState(true)
   
    useEffect(() => {
        const abortController = new AbortController();
        getDisplaysAsync(abortController);
        return () => {
            abortController.abort();
          };
      }, []);

    const getDisplaysAsync = async (abortController:AbortController)  => {
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null); // null can't reuse abort controller in parrralel requests
            var getPlayListPromise = axios.get<PlayListDto[]>(config.backEndAppApi + `/api/GetPlayLists`, axiosRequestConfig);
            var result = await axios.get<Display[]>(config.backEndAppApi + `/api/GetDisplays`, axiosRequestConfig);
            var playListResult = await getPlayListPromise;
            playLists.current = playListResult.data;
            setDisplays(result.data);
            registrationCodes.current = new  Array<string>(result.data.length);
            
        }
        catch {

        }
        finally{
            setLoading(false);
        }
    }  

    const HandleLinkClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        
        var splitted = event.currentTarget.value.split(",", 2); 
        let id:string = splitted[0];
        let index:number = +splitted[1];
        let registrationCode = registrationCodes.current[index];

        console.log(id);
        console.log(registrationCode);
        if (registrationCode === undefined ||registrationCode === null || registrationCode === "") {
            alert('enter registration code first');
            return;
        }
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"displayId":id, "registrationCode":registrationCode}
            await axios.get(config.backEndAppApi + `/api/LinkDisplay`, axiosRequestConfig);
            // no error: change the state
            let copiedDisplays = [...displays];
            copiedDisplays[index].linkedToDevice = true;
            setDisplays(copiedDisplays);
        }
        catch(error) {
            console.log(error);
        }
    }

    const HandleUnlinkClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        let id:string = event.currentTarget.value;
        let idAsNumber = +id;
        console.log(id);
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"displayId":id}
            await axios.get(config.backEndAppApi + `/api/UnlinkDisplay`, axiosRequestConfig);
            // no error: change the state
            let copiedDisplays = [...displays];
            copiedDisplays.filter(r => r.id === idAsNumber).map(r=> r.linkedToDevice = false); //this should only update one
            setDisplays(copiedDisplays);
        }
        catch(error) {
            console.log(error);
        }
    }
    

    const HandleRegistrationChange = (event:ChangeEvent<HTMLInputElement>) => {
        var index = +event.currentTarget.id; // + converts to a number
        var newArray = {...registrationCodes.current};
        newArray[index]=event.currentTarget.value;
        registrationCodes.current = newArray;
    }

    const HandleDisplayNameChange = (event:ChangeEvent<HTMLInputElement>) => {
        setNewDisplayName(event.currentTarget.value);
    }

    const HandleAddClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        
        if (newDisplayName === "")
        {
            alert('Enter name');
            return;
        }
        
        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"displayName":newDisplayName}
            var newDisplay= await axios.get<Display>(config.backEndAppApi + `/api/AddDisplay`, axiosRequestConfig);
            let copiedDisplays = [...displays, newDisplay.data];
            setDisplays(copiedDisplays);
            setNewDisplayName("");
        }
        catch(error) {
            console.log(error);
        }
    }

    const GetPlayListName = (playListId:number):string => {
        if (playListId === undefined || playListId < 0) {
            return '-';
        }

        var playList = playLists.current.find(i => i.id === playListId);
        if (playList === undefined) {
            return `na (${playListId})`;
        }

        return `${playList.description} - ${playList.id}`;
        
    }

    const onPlayListSelectionChanged = async (event: FormEvent<HTMLSelectElement>) => {
        //for now-> just update the display
        var splitted = event.currentTarget.value.split(",", 2); 
        let idAsString:string = splitted[0];
        let playListIdAsString:string = splitted[1];

        let id:number = +idAsString;
        let playListId:number = +playListIdAsString;

        console.log(idAsString);
        console.log(playListIdAsString);

        let displayName = displays.find(i => i.id == id)?.name;

        try {
            let axiosRequestConfig = await context.getAxiosRequestObjectAsync(null);
            axiosRequestConfig.params = {"id":idAsString, "name": displayName, "playListId":playListIdAsString}
            await axios.get(config.backEndAppApi + `/api/UpDateDisplay`, axiosRequestConfig);

            let copiedDisplays = [...displays];
            copiedDisplays.filter(r => r.id === id).map(r=> {
                r.playListid = playListId;
            }); //this should only update one
            setDisplays(copiedDisplays);
        }
        catch(error) {
            console.log(error);
        }

    }
    
    function getOptionValue (id:number, playListId:number ) {
        return `${id},${playListId}`;
    }

    if (loading) {
        return (<LoadingComponent/>);
    }

    function GetActivityTimeString(date:Date): string {
        if (date === null) {
            return '_';
        }
        var localDate = moment(date).local();
        return localDate.format("YYYY-MM-DD HH:mm:ss");
    }
    

    return(
        <div className="rounded-t-xl overflow-hidden bg-blue to-teal-100 p-10">
              <h1 className='underline text-xl'>Displays</h1>
            <table className='table-auto'>
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Playlist</th>
                        <th>Last activity</th>
                        <th>Link/Unlink</th>
                        
                    </tr>
                </thead>
                <tbody>
             {displays.map((d, index) => (<tr key={`${d.id}`}>
                    <td>
                        <div className='mr-10'>{d.id}</div>
                    </td>
                    <td>
                        <div className='mr-10'>{d.name}</div>
                    </td>
                    <td>
                        <div className='mr-10'>
                            <select onChange={onPlayListSelectionChanged}  defaultValue= {getOptionValue(d.id, d.playListid)}>
                                <option key={`-1`} value={getOptionValue(d.id, -1)}>(none)</option>
                                {playLists.current.map( i=>(<option key={`${i.id}`} value={getOptionValue(d.id, i.id)}>{GetPlayListName(i.id)} </option>))}
                            </select>
                        </div>
                    </td>
                    <td>
                    <div className='mr-10'>{GetActivityTimeString(d?.lastActivityTimeStamp)}</div>
                    </td>
                    <td>
                        <div className='ml-0'>
                            {d.linkedToDevice===true?
                            (<button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" value={[`${d.id}`]} onClick={HandleUnlinkClick}>Unlink</button>)
                            :
                            (<div>Registration code: 
                                <input  className='rounded border' id={`${index}`} onChange={HandleRegistrationChange}/>
                                <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" value={[`${d.id}`, `${index}`]} onClick={HandleLinkClick}>Link</button>
                            </div>)}
                        </div>
                    </td>
                        
                </tr>))} 

             </tbody>
            </table>
            <div>
                <br/>
                <br/>
                <input className='rounded border' onChange={HandleDisplayNameChange} value={newDisplayName}/>
                <button className="bg-blue-300 text-white font-bold py-2 px-4 rounded" onClick={HandleAddClick}>Add</button>
            </div>
        </div>
        );
}

export default Page_Displays;

