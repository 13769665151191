import React from 'react';
import { PropsWithChildren } from 'react';
import { AuthStatus } from '../authStatus';
import { useIonScreenAuth } from '../Hooks/useIonScreenAuth';

export function TemplateAuthenticationError ({children}:PropsWithChildren): React.ReactElement|null {

    const context = useIonScreenAuth();
    if (context.authStatus == AuthStatus.Error) {
        return (
            <React.Fragment>
                {children}
            </React.Fragment>

        );
    }
    return null;

}