import { AuthenticationResult, AuthError, EventMessage, EventType, InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import axios, { AxiosRequestConfig } from "axios";
import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { loginRequest } from "../../authConfig";
import config from "../../Config";
import { IHelloDto, ITenantDto } from "../../DTO/HelloDto";
import { AuthStatus } from "../authStatus";
import {  defaultIonScreenAuthContext, IIonScreenAuthContext, IonScreenAuthContext  } from "../IonScreenAuthContext";





export function AuthenticationProvider({children}:PropsWithChildren): React.ReactElement|null {
    // User is the name of the "data" that gets stored in context
    const [authenticationState, setAuthenticationState] = useState<IIonScreenAuthContext>(defaultIonScreenAuthContext);
    
    const { instance, accounts } = useMsal();
    const msalCallBackId = useRef<string|null>();

    /*const value = useMemo(
      () => ({ userName, setUserName }), 
      [userName]
    );*/

    
    //console.log('provider rendering');

    
    /*const setUserData = useCallback((currentTenandId:number) => {
      setUserState({...userState, isRegistered:true, currentTenandId:currentTenandId}) 
    }, [userState, setUserState]);
    */

    const getBaseAxiosRequestConfigAsync = async (abortController:AbortController|null):Promise<AxiosRequestConfig> => {

      if (accounts.length == 0) {
            throw new Error("This function can only be called after b2C logon was done");
      }

      const tokenRequest = {scopes: [`https://ionscreen.onmicrosoft.com/AppApi/Hello`]};
    
      let accessToken:string= "";
      try
      {
          let response:AuthenticationResult = await instance.acquireTokenSilent(tokenRequest); 
  
          if (!response.accessToken || response.accessToken === "") {
              throw new InteractionRequiredAuthError("MISSING_ACCESSTOKEN", "Response had no access token");
          }
          accessToken = response.accessToken;
      } 
      catch(error){
          if (error instanceof InteractionRequiredAuthError) {
              // fallback to interaction when silent call fails
              try{
                  let response = await instance.acquireTokenRedirect(tokenRequest); 
              }
              catch(anotherError) {
                  console.log(anotherError);
                  throw new Error(`There was a problem getting the access token: ${error}`);
              }
          } else {
              console.log(error);
              throw new Error(`There was a problem getting the access token: ${error}`);
          }
      }
    
      var axiosRequestConfig:AxiosRequestConfig = {
        signal: abortController?.signal??undefined,
        headers: {
          Authorization: `Bearer ${accessToken}`                ,
          tenantId: authenticationState.currentTenantInfo.id //this can be -1 for some calls
          }
      };

      return axiosRequestConfig
    }

    
    useEffect( () => {
        const getAuthenticationStatusAsync = async (abortController:AbortController) => {

          console.log('Account length:', accounts.length);
          try
          {
            if(msalCallBackId.current === "")
            {
              let callbackId = instance.addEventCallback((message: EventMessage) => {
                // Update UI or interact with EventMessage here
                if (message.eventType === EventType.LOGIN_FAILURE) {
                    if (message.error instanceof AuthError) {
                        // Do something with the error
                        setAuthenticationState({...authenticationState, authStatus:AuthStatus.Error, error: `B2C auth error: ${message.error.errorMessage}` });
                    }
                }
              });
              msalCallBackId.current = callbackId;
            }
            if (accounts.length == 0)
            {
              //instance.loginRedirect(loginRequest); -> it will be done too much
              console.info("B2C login is not done yet");
              return;
            }
            instance.setActiveAccount(instance.getAllAccounts()[0]); // we only work with a single account
            //ok at this point we know we're logged in to b2c -> use this username for now
            setAuthenticationState({...authenticationState, authStatus:AuthStatus.Loading, userName:accounts[0].username });
          
            let requestConfig = await getBaseAxiosRequestConfigAsync(abortController);
                
            let result = await axios.get<IHelloDto>(config.backEndAppApi + '/api/Hello', requestConfig);
            let helloDto = result.data;

            if (!helloDto.knownUser) {
              console.info("Logged in, but not registered");      
              setAuthenticationState({...authenticationState, authStatus:AuthStatus.LoggedInButNotRegistered });        
            } else {
              let tenantInfo= helloDto.tenants.find(t => t.id === helloDto.defaultTenantId) as ITenantDto;
              setAuthenticationState({...authenticationState, authStatus:AuthStatus.LoggedIn, userName:helloDto.displayName, currentTenantInfo:{...tenantInfo} });        
            }
          }
            catch (error){
              setAuthenticationState({...authenticationState, authStatus:AuthStatus.Error, error: `Hello call failed: ${error}` });
              return;
          }
          
        } //end async hack


        
        const abortController = new AbortController();
        getAuthenticationStatusAsync(abortController);
        return () => {
            abortController.abort();
          };
        

        /*if (myMSALErrorOccurred)
        {
          setLoading(false);
          setError({error:true, errorMessage:`Authentication failed ${myMSALErrorMessage}`});
          resetMSALError(); //set ready for retry
          return; //done here
        }
         
        const account = myMSALObj.getAccount();

        if (account !== undefined && account !== null) {

          //myMSALObj.logout();
          //var defaultName = JSON.stringify(account.idTokenClaims);
            var defaultName = account.idTokenClaims["emails"][0];
            fetchData();
        } else {
          // do a wakeup call to warmup the Azure functions (but do not wait for the result)
          axios.get(config.backEndApi + '/api/wakeup');
          myMSALObj.loginRedirect(loginRequest);
        }
        */
    
      }, [accounts.length]); // make sure this is a primitive, or sth memoized


    return (
      <IonScreenAuthContext.Provider value={{
        authStatus:authenticationState.authStatus,
        error:authenticationState.error,
        userName:authenticationState.userName,
        currentTenantInfo:{...authenticationState.currentTenantInfo},
        getAxiosRequestObjectAsync:getBaseAxiosRequestConfigAsync
      }}>
        
        {children}
            
      </IonScreenAuthContext.Provider>
    );
  }